.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.justy-main-card {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.justy-main-card > .ant-card-cover {
  flex: 1;
}

.justy-main-card > .ant-card-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.justy-main-card > .ant-card-body > .ant-row-flex {
  justify-self: flex-end;
  flex: 1;
}